import React from "react";
import {css, styled} from "../../../core/styledComponents";
// import {IconFacebookMessanger} from "../../../shared/icons/IconFacebookMessanger";
import {IconICQ} from "../../../shared/icons/IconICQ";
// import {IconSkype} from "../../../shared/icons/IconSkype";
import {IconTelegram} from "../../../shared/icons/IconTelegram";
import {IconViber} from "../../../shared/icons/IconViber";
import {IconWatsapp} from "../../../shared/icons/IconWatsapp";

interface IProps {
  noTitle?: boolean;
  noHideOnMobile?: boolean;
}

export const SharedContent: React.FC<IProps> = (props) => {
  const [visibleICQ, setVisibleICQ] = React.useState(false);

  const clickICQLink = () => {
    setVisibleICQ(!visibleICQ);
  };

  return <Container noTitle={true} noHideOnMobile={props.noHideOnMobile}>
    {!props.noTitle && <Title>Свяжитесь с нами:</Title>}
    <div style={{display: "flex"}}>
      <Item target={"_blank"} href={"https://t.me/pmr_mdbot"}>
        <IconTelegram width={"32px"} height={"32px"}/>
      </Item>
      <Item target={"_blank"} href={"viber://chat?number=+37377581111"}>
        <IconViber height={"32px"} width={"32px"}/>
      </Item>
      <Item target={"_blank"} href={"https://wa.me:/+37377581111"}>
        <IconWatsapp width={"32px"} height={"32px"}/>
      </Item>
      <ICQ onClick={clickICQLink} visibleICQ={visibleICQ}>
        <IconICQ width={"32px"} height={"32px"}/>
        <span className={"tooltips"}>+37377581111</span>
      </ICQ>
      {/*<Item target={"_blank"} href={"skype:info@pmr.md?chat"}>*/}
      {/*  <IconSkype width={"32px"} height={"32px"}/>*/}
      {/*</Item>*/}
      {/*<Item target={"_blank"} href={"https://m.me/sprav.pmr"}>*/}
      {/*  <IconFacebookMessanger height={"32px"} width={"32px"}/>*/}
      {/*</Item>*/}
    </div>
  </Container>;

};

const Container = styled.div<{noTitle?: boolean; noHideOnMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: flex-start;
  margin-right: 13px;
  margin-top: ${(props) => (props.noTitle ? 0 : 21)}px;
  @media (max-width: 420px) {
    ${(props) => props.noHideOnMobile !== true && css`display: none;`};
  }

  @media (max-width: 991px) {
    align-self: center;
    margin-right: 0;
    align-items: center;
    padding-bottom: 20px;
  }
`;

const Title = styled.div`
  margin-right: 5px;
  font-family: SFUITextMedium, sans-serif;
  font-size: 20px;
  margin-bottom: 8px;
  color: #4a4a4a;
`;

const Item = styled.a`
  display: block;
  margin-right: 15px;
  cursor: pointer;
  img {
    height: 32px;
    width: auto;
  }
`;

const ICQ = styled.div<{visibleICQ?: boolean}>`
  display: inline-block;
  position: relative;
  margin-right: 15px;
  cursor: pointer;

  img {
    height: 32px;
    width: auto;
  }

  .tooltips {
    display: ${(props) => (props.visibleICQ ? "flex" : "none")};
    border-radius: 8px;
    padding: 4px;
    font-family: SFUIText, sans-serif;
    font-size: 8px;
    position: absolute;
    background: #37474f;
    color: white;
    left: -50%;
    ::after {
      width: 10px;
      content: " ";
      position: absolute;
      top: -50%;
      left: 41%;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent #37474f transparent;
    }
  }
`;
